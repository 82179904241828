const priceTypeEnum = {
  STATIC: 1,
  DYNAMIC: 2,
} as const

type PriceEnum = typeof priceTypeEnum

export const transformPriceEnum = <TValue extends object & { priceType: keyof PriceEnum }>(
  val: TValue
): {
  [P in keyof TValue]: P extends 'priceType' ? PriceEnum[TValue[P]] : TValue[P]
} => {
  return { ...val, priceType: priceTypeEnum[val.priceType] }
}
