import { ActiveSessionSchema, ShorePowerUnitSchema } from '@fjuel/schemas'
import { z } from 'zod'
import { IPCSchema } from './IPCSchema'

export const ShorePowerUnitWithActiveSessionSchema = ShorePowerUnitSchema.extend({
  session: ActiveSessionSchema.nullable(),
})
export type ShorePowerUnitWithActiveSession = z.infer<typeof ShorePowerUnitWithActiveSessionSchema>

export const IPCWithShorePowerUnitSchema = IPCSchema.extend({
  shorePowerUnits: z.array(
    ShorePowerUnitSchema.extend({
      session: ActiveSessionSchema.nullable(),
    })
  ),
})

export type IPCWithShorePowerUnit = z.infer<typeof IPCWithShorePowerUnitSchema>
