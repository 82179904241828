import { z } from 'zod'

export const CompanyDtoSchema = z.object({
  id: z.string().uuid().nullable(),
  name: z.string().nullable(),
  orgNr: z.string().nullable(),
  siteId: z.string().uuid().nullable(),
  businessAddressStreetName: z.string().nullable(),
  businessAddressCity: z.string().nullable(),
  businessAddressZipCode: z.string().nullable(),
  businessAddressCountry: z.string().nullable(),
  mailingAddressStreetName: z.string().nullable(),
  mailingAddressCity: z.string().nullable(),
  mailingAddressZipCode: z.string().nullable(),
  mailingAddressCountry: z.string().nullable(),
})

export type SiteCompany = z.infer<typeof CompanyDtoSchema>
