import { fjuelApi } from '@fjuel/configs/api'
import { disableIfVariablesUndefined } from '@fjuel/hooks/queries/middlewares/disableIfVariablesUndefined'
import { CompanyDtoSchema, SiteCompany } from '@fjuel/schemas'
import { createQuery } from 'react-query-kit'
import { z } from 'zod'

export const useSiteCompanies = createQuery<SiteCompany[], { siteId: string | undefined }>({
  primaryKey: 'site-companies',
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    z.array(CompanyDtoSchema).parse(await fjuelApi.get(`site/${siteId}/companies`).json()),
  use: [disableIfVariablesUndefined('siteId')],
})
