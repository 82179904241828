import { z } from 'zod'

export const ShorePowerUnitPlugSpecSchema = z.object({
  is50Hz: z.boolean(),
  is60Hz: z.boolean(),
  amperage: z.number().min(0).describe('A'),
  effect: z.number().min(0).describe('kVA'),
  voltage: z.number().min(0).describe('V'),
  note: z.string().nullable(),
})

export type ShorePowerUnitPlugSpec = z.infer<typeof ShorePowerUnitPlugSpecSchema>
