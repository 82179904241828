import { Middleware, QueryHook } from 'react-query-kit'

export function disableIfVariablesUndefined<TData, TVariables extends object>(
  variablesToCheck: (keyof TVariables)[] | keyof TVariables
): Middleware<QueryHook<TData, TVariables>> {
  return (useQueryNext) => {
    return ({ variables, ...options }) => {
      if (!Array.isArray(variablesToCheck)) {
        variablesToCheck = [variablesToCheck]
      }
      return useQueryNext({
        ...options,
        variables,
        enabled: !variablesToCheck.some((key) => variables?.[key] === undefined),
      })
    }
  }
}
