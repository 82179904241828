import { fjuelApi } from '@fjuel/configs/api'
import { disableIfVariablesUndefined } from '@fjuel/hooks/queries/middlewares/disableIfVariablesUndefined'
import { Session, SessionSchema } from '@fjuel/schemas'
import { createQuery } from 'react-query-kit'
import { z } from 'zod'

export const useVesselSessions = createQuery<Session[], { vesselId: string | undefined }>({
  primaryKey: 'vessel-sessions',
  queryFn: ({ queryKey: [, { vesselId }] }) =>
    z.promise(z.array(SessionSchema)).parse(fjuelApi.get(`vessel/${vesselId}/sessions`).json()),
  use: [disableIfVariablesUndefined('vesselId')],
})
