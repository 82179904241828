import { Box, Button, MenuItem, Pagination, PaginationItem, Select, Typography } from '@mui/material'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { FC } from 'react'

const itemsPerPage = [10, 25, 50, 100]

export const TablePagination: FC<{
  count: number
  page: number
  rowsPerPage: number
  onRowsPerPageChange: (rowsPerPage: number) => void
  onPageChange: (page: number) => void
  siblingCount?: number
}> = ({ count, page, rowsPerPage, onRowsPerPageChange, onPageChange, siblingCount }) => {
  const { start, end } = getStartAndEnd({ page, rowsPerPage, count })
  const buttonSx = {
    '@container (max-width: 800px)': {
      fontSize: 0,
      '.MuiButton-endIcon, .MuiButton-startIcon': { mx: 0 },
      minWidth: 0,
    },
  }
  return (
    <Box sx={{ containerType: 'inline-size' }}>
      <Box
        display={'grid'}
        sx={{
          gridTemplateColumns: '1fr auto 1fr',
          '@container (max-width: 600px)': { gridTemplateColumns: '2fr 4fr 1fr' },
        }}
        justifyContent={'space-between'}
        py={2}
        alignItems={'center'}
        gap={2}
      >
        <Typography fontWeight={600} sx={{ '@container (max-width: 800px)': { display: 'none' } }}>
          Shows {start} - {end} of {count} entries
        </Typography>
        <Typography fontWeight={600} sx={{ '@container (min-width: 800px)': { display: 'none' } }}>
          {start} - {end} of {count}
        </Typography>
        <Pagination
          sx={{ justifySelf: 'center' }}
          siblingCount={siblingCount}
          color="primary"
          boundaryCount={2}
          page={page + 1}
          onChange={(_e, page) => onPageChange(page)}
          count={getPages({ count, rowsPerPage })}
          renderItem={(item) => {
            if (item.type === 'start-ellipsis') return <Typography>...</Typography>
            if (item.type === 'end-ellipsis') return <Typography>...</Typography>
            if (item.type === 'page') return <PaginationItem {...item} onClick={() => onPageChange(item.page! - 1)} />
            if (item.type === 'previous') {
              return (
                <Button
                  disabled={item.disabled}
                  onClick={() => onPageChange(page - 1)}
                  startIcon={<CaretLeft />}
                  aria-label="Previous page"
                  sx={buttonSx}
                >
                  Previous
                </Button>
              )
            }
            if (item.type === 'next') {
              return (
                <Button
                  aria-label="Next page"
                  sx={buttonSx}
                  disabled={item.disabled}
                  onClick={() => onPageChange(page + 1)}
                  endIcon={<CaretRight />}
                >
                  Next
                </Button>
              )
            }
          }}
        />
        <Box display={'flex'} alignItems={'center'} gap={1} justifySelf={'end'}>
          <Typography fontWeight={600} sx={{ '@container (max-width: 800px)': { display: 'none' } }}>
            Rows per page:
          </Typography>
          <Select
            size="small"
            autoWidth
            aria-label="Select rows per page"
            value={rowsPerPage ?? ''}
            onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
          >
            {itemsPerPage.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  )
}

const getStartAndEnd = ({ page, rowsPerPage, count }: { page: number; rowsPerPage: number; count: number }) => {
  if (count === 0) return { start: 0, end: 0 }

  const start = page * rowsPerPage + 1
  const end = Math.min((page + 1) * rowsPerPage, count)

  return { start, end }
}

const getPages = ({ rowsPerPage, count }: { rowsPerPage: number; count: number }) => {
  return Math.ceil(count / rowsPerPage)
}
