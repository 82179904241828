import { SvgIcon } from '@mui/material'
import { FC } from 'react'

export const Plug: FC<{ weight?: 'duotone' }> = ({ weight }) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        fillOpacity={0.3}
        height="25"
        viewBox="0 0 20 25"
        fill={weight !== 'duotone' ? 'none' : 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        strokeWidth="1.71429"
      >
        <path d="M13.3256 19.2752H6.67447C3.83416 19.2752 1.53162 16.9727 1.53162 14.1324V9.13372C1.53162 8.18695 2.29912 7.41943 3.2459 7.41943H16.7541C17.7009 7.41943 18.4684 8.18695 18.4684 9.13372V14.1324C18.4684 16.9727 16.1659 19.2752 13.3256 19.2752Z" />
        <path d="M5.76575 7.4191V1.49121" />
        <path d="M14.2343 7.4191V1.49121" />
        <path d="M10 19.2749V23.5092" />
      </svg>
    </SvgIcon>
  )
}
