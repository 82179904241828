import { fjuelApi } from '@fjuel/configs/api'
import { PriceMarkupResponseSchema } from '@fjuel/schemas'
import { createQuery } from 'react-query-kit'
import { z } from 'zod'
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined'

export const useSitePriceMarkups = createQuery<
  z.infer<typeof PriceMarkupResponseSchema>,
  { siteId: string | undefined }
>({
  primaryKey: 'site-price-markups',
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    PriceMarkupResponseSchema.parse(await fjuelApi.get(`site/${siteId}/pricemarkups`).json()).filter(
      // TODO: Remove this filter when the API is consolidated with the new price markup model
      (v) => v.priceType === 'STATIC'
    ),
  use: [disableIfVariablesUndefined(['siteId'])],
})
