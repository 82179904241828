import { fjuelApi } from '@fjuel/configs/api'
import { UserWithPermission } from '@fjuel/schemas'
import { createMutation } from 'react-query-kit'

export const useToggleUserActive = createMutation({
  mutationFn: ({ isActive, id }: UserWithPermission) => {
    const endpoint = isActive ? 'deactivate' : 'activate'
    return fjuelApi.post(`user/${id}/${endpoint}`)
  },
})
