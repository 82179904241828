import { z } from 'zod'
import { FrequencySchema } from './FrequencySchema'
import { IMOSchema } from './IMOSchema'
import { ShorePowerUnitSchema } from './ShorePowerUnitSchema'
import { VesselSchema } from './VesselSchema'
import { VoltageSchema } from './VoltageSchema'

export const SessionSchema = z
  .object({
    id: z.string().uuid(),
    shorePowerUnitId: z.string().uuid(),
    shorePowerUnit: ShorePowerUnitSchema,
    vesselImo: IMOSchema.nullable(),
    vesselImoChecked: z.boolean(),
    voltage: VoltageSchema,
    frequency: FrequencySchema,
    amperage: z.number(),
    powerPeak: z.number().nonnegative(),
    totalEnergyConsumed: z.number(),
    averagePower: z.number().nonnegative(),
    timeStarted: z.coerce.date(),
    timeEnded: z.coerce.date().nullable(),
    invoiced: z.boolean(),
    siteCompanyId: z.string().uuid().nullable().default(null),
  })
  .and(
    z
      .object({
        vesselId: z.null(),
      })
      .or(
        z.object({
          vesselId: z.string().uuid(),
          vessel: VesselSchema,
        })
      )
  )

export type Session = z.infer<typeof SessionSchema>
