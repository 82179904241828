import { z } from 'zod'
import { CompanyDtoSchema } from '../CompanyDtoSchema'
import { IPCSchema } from '../ipc/IPCSchema'
import { EffectMarkupsSchema } from './EffectMarkupsSchema'

export const BasePriceMarkupSchema = z.object({
  id: z.string().uuid().nullable().default(null).describe('Unique identifier of the price markup'),
  ipcId: z.string().uuid().nullable().default(null).describe('Unique identifier of the IPC'),
  ipc: IPCSchema,
  siteCompanyId: z.string().uuid().nullable().default(null).describe('Unique identifier of the company'),
  siteCompany: CompanyDtoSchema.omit({ siteId: true, id: true }).nullable().default(null),
  cableHandlingKr: z.coerce.number().int().positive().describe('Static cost of handling the cable connection'),
  effectMarkups: EffectMarkupsSchema,
})

export const StaticPriceMarkup = z.object({
  priceType: z.literal('STATIC'),
  orePerKwh: z.coerce.number().int().positive().describe('Static markup per kWh in NOK/øre'),
})
export type StaticPriceMarkup = z.infer<typeof StaticPriceMarkup>

export const DynamicPriceMarkup = z.object({
  priceType: z.literal('DYNAMIC'),
  spotPricePercentage: z.coerce.number().positive().describe('Percentage of the spot price added to the price'),
})

export type DynamicPriceMarkup = z.infer<typeof DynamicPriceMarkup>

export const PriceMarkupSchema = z.discriminatedUnion('priceType', [
  StaticPriceMarkup.merge(BasePriceMarkupSchema),
  DynamicPriceMarkup.merge(BasePriceMarkupSchema),
])

export type PriceMarkup = z.infer<typeof PriceMarkupSchema>
