import { VesselTypeIcon } from '@fjuel/pages/session/VesselTypeIcon'
import { ShorePowerUnitActiveSession } from '@fjuel/pages/shorePowerUnits/ActiveSessionTable'
import { ActiveSessionSchema, VesselTypes } from '@fjuel/schemas'
import { dateTimeShortFormatter, formatDurationShort, getVesselTypeName } from '@fjuel/utils'
import { Box, Button, Divider, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { X } from '@phosphor-icons/react'
import { intervalToDuration, startOfMinute } from 'date-fns'
import { ComponentProps, FC } from 'react'
import { TextWithIcon } from '..'
import { InlineStat } from '../InlineStat'
import { CapacityGraph } from '../graphs/CapacityGraph'
import { GlowingCircle } from '../icons/GlowingCircle'

export const ContainerPopover: FC<{
  unit: ShorePowerUnitActiveSession
  onCloseClicked?: VoidFunction
  onSeeDetailsClicked?: (unit: ShorePowerUnitActiveSession) => void
}> = ({ unit, onCloseClicked, onSeeDetailsClicked }) => {
  const { palette } = useTheme()

  // Mapbox serializes the session object to a string, so we need to parse it
  const session = typeof unit.session === 'string' ? ActiveSessionSchema.parse(JSON.parse(unit.session)) : unit.session

  return (
    <Box display={'flex'} gap={1} flexDirection={'column'} p={1}>
      <Box display={'flex'} gap={1} whiteSpace={'nowrap'} alignItems={'center'}>
        <Typography whiteSpace={'normal'} fontWeight={600} fontSize={22}>
          {unit.name}
        </Typography>
        {session && <ChargingIndicator isCharging={!!session.activePower && session.activePower > 0} />}

        <Button
          sx={{ textTransform: 'none', ml: 'auto', borderRadius: 2, px: 2.5, py: 0.5 }}
          variant="outlined"
          onClick={() => onSeeDetailsClicked?.({ ...unit, session })}
        >
          See details
        </Button>
        <IconButton onClick={() => onCloseClicked?.()} size="small">
          <X color={palette.primary.main} />
        </IconButton>
      </Box>
      <Typography>{unit.ipcName}</Typography>
      {session && (
        <Box display={'grid'} gridTemplateColumns={'1fr auto auto'} gap={2}>
          <Box display="flex" gap={1} alignItems={'center'}>
            <VesselTypeIconWithTooltip vesselType={session.vessel.shipType} size={28} />
            <Box>
              <Typography fontWeight={300} fontSize={12}>
                IMO {session.vessel.imo}
              </Typography>
              <Typography fontWeight={600} fontSize={14}>
                {session.vessel.name}
              </Typography>
            </Box>
          </Box>
          <InlineStat
            label={'Start'}
            sx={{ whiteSpace: 'nowrap' }}
            value={dateTimeShortFormatter(session.timeStarted)}
          />
          <InlineStat
            label={'Duration'}
            sx={{ whiteSpace: 'nowrap' }}
            value={formatDurationShort(
              intervalToDuration({ start: session.timeStarted, end: startOfMinute(new Date()) })
            )}
          />
          {(session.activePower ?? 0) > 0 && (
            <>
              <Divider sx={{ gridColumn: '1 / -1', borderColor: 'hsla(182, 44%, 33%, 1)' }} />
              <Box display="grid" gridTemplateColumns={'1fr 1fr'} gap={1} justifyContent={'start'}>
                <Box>
                  <Typography fontWeight={300} fontSize={12}>
                    Active power (kW)
                  </Typography>
                  {/*TODO: Is max load for the current IPC or unit? */}
                  <CapacityGraph
                    currentLoad={session.activePower ?? 0}
                    maxLoad={300}
                    dataLabelSuffix="kW"
                    style={{ width: '100px', height: '100px' }}
                  />
                </Box>
                {/* TODO What is this?*/}
                {/* <Box>
                  <Typography fontWeight={300} fontSize={12}>
                    Energy (kWh)
                  </Typography>
                  <CapacityGraph currentLoad={session.activePower ?? 0} maxLoad={1500} style={{ width: '100px', height: '100px' }} />
                </Box> */}
              </Box>
              <Box>
                <InlineStat label={'Voltage'} value={(session.voltage ?? 0) + 'V'} />
                <InlineStat label={'Current'} value={(session.amperage ?? 0) + ' A'} />
                <InlineStat label={'Frequency'} value={(session.frequency ?? 0) + ' Hz'} />
              </Box>
              <Box>
                <InlineStat label={'Peak active power (kW)'} value={(session.powerPeak ?? 0) + ''} />
                <InlineStat label={'Avg. active power (kW)'} value={(session.averagePower ?? 0) + ''} />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

export type ContainerPopoverProps = ComponentProps<typeof ContainerPopover>

const VesselTypeIconWithTooltip: FC<{ vesselType: VesselTypes; size: number }> = ({ vesselType, ...rest }) => {
  return (
    <Tooltip title={getVesselTypeName(vesselType)} placement="top" arrow>
      <VesselTypeIcon vesselType={vesselType} {...rest} />
    </Tooltip>
  )
}

const ChargingIndicator: FC<{ isCharging: boolean }> = ({ isCharging }) => (
  <>
    {isCharging ? (
      <TextWithIcon gap={0.5} color="primary" startIcon={<GlowingCircle color="lightGreen" />}>
        Charging
      </TextWithIcon>
    ) : (
      <TextWithIcon gap={0.5} color="secondary" startIcon={<GlowingCircle color="pink" />}>
        Not charging
      </TextWithIcon>
    )}
  </>
)
