import { ShorePowerUnit, Vessel } from '@fjuel/schemas'

export const getStatusColor = (status: ShorePowerUnit['status'] | Vessel['status']) => {
  switch (status) {
    case 'unknown':
    case null:
    case 'available':
      return 'hsla(0, 0%, 100%, 0.8)'
    case 'occupied':
    case 'moored and charging':
    case 'active':
      return 'hsla(143, 95%, 77%, 1)'
    case 'out-of-order':
    case 'anchored':
    case 'moored':
    case 'sailing':
      return 'hsla(333, 75%, 60%, 1)'
    default: {
      const _exhaustiveCheck: never = status
      console.warn('⚠️  _exhaustiveCheck:', _exhaustiveCheck)
      return 'hsla(0, 0%, 100%, 0.8)'
    }
  }
}
