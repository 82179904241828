import { z } from 'zod'
import { CableHandlingCostSchema } from './CableHandlingCostSchema'
import { EffectMarkupsSchema } from './EffectMarkupsSchema'
import { DynamicPriceMarkup, StaticPriceMarkup } from './PriceMarkupSchema'

const BaseCreatePriceMarkupSchema = z.object({
  ipcId: z.string().uuid(),
  siteCompanyId: z.string().uuid().nullish(),
  cableHandlingKr: CableHandlingCostSchema,
  effectMarkups: EffectMarkupsSchema,
})
export const CreatePriceMarkupSchema = z
  .discriminatedUnion('priceType', [StaticPriceMarkup, DynamicPriceMarkup])
  .and(BaseCreatePriceMarkupSchema)

export type CreatePriceMarkup = z.infer<typeof CreatePriceMarkupSchema>
