import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useApplicationContext } from './ApplicationContext'
import { useSites } from './hooks'

export const SiteSelector: FC = () => {
  const { siteId: currentSiteId, setSiteId } = useApplicationContext()
  const { data: sites } = useSites()
  const location = useLocation()

  const navigate = useNavigate()
  const { siteId } = useApplicationContext()
  if (!sites) return null
  if (sites.length === 1) {
    return (
      <Tooltip title={'Only access to one site'} placement="top" arrow enterDelay={500}>
        <TextField
          margin="normal"
          size="small"
          disabled
          sx={{ my: 2 }}
          label="Site"
          value={`Port of ${sites[0]!.name}`}
        />
      </Tooltip>
    )
  }

  return (
    <FormControl fullWidth sx={{ my: 2 }} size="small">
      <InputLabel id="site-select-label">Site</InputLabel>
      <Select
        labelId="site-select-label"
        label="Site"
        value={siteId ?? ''}
        onChange={(e) => {
          if (!currentSiteId) return
          const newSiteId = e.target.value
          setSiteId(newSiteId)
          return navigate(location.pathname.replace(currentSiteId, newSiteId))
        }}
      >
        {sites?.map((site) => (
          <MenuItem key={site.id} value={site.id}>
            Port of {site.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
