import { Box, Button, ButtonProps, Collapse, List, ListItem, ListItemText } from '@mui/material'
import {
  Anchor,
  Boat,
  Buildings,
  Chalkboard,
  FlagPennant,
  Lightning,
  Lock,
  Receipt,
  Table,
  Tag,
  Users,
} from '@phosphor-icons/react'
import { FC, PropsWithChildren } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useSessionStorage } from 'usehooks-ts'
import { useApplicationContext } from './ApplicationContext'
import { ProfileMenu } from './ProfileMenu'
import { SiteSelector } from './SiteSelector'
import FjuelLogo from './assets/fjuel.svg?react'
import { Plug } from './components/icons/Plug'
import { PriceAndPay } from './components/icons/PriceAndPay'
import { useMe } from './hooks'

export const SideMenu = () => {
  const { siteId } = useApplicationContext()
  const { data: me } = useMe()
  const [openMenuItems, setOpenMenuItems] = useSessionStorage<{
    shorePowerConnect: boolean
    pricingAndPaymentSolution: boolean
    portCallPlaner: boolean
  }>('menuItemOpen', {
    pricingAndPaymentSolution: false,
    shorePowerConnect: true,
    portCallPlaner: false,
  })

  const closeAllBut = (key: keyof typeof openMenuItems) => () => {
    const toClose = Object.keys(openMenuItems).filter((k) => k !== key)
    const newState = toClose.reduce((acc, k) => ({ ...acc, [k]: false }), {})
    setOpenMenuItems((prev) => ({ ...prev, ...newState }))
  }

  const toggleItem = (key: keyof typeof openMenuItems) => () => {
    setOpenMenuItems((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  return (
    <Box
      p={2}
      zIndex={1000}
      minWidth={0}
      maxWidth={'262px'}
      component="aside"
      display="flex"
      position="fixed"
      alignItems="start"
      flexDirection="column"
      height="100dvh"
    >
      <Box
        to={siteId ? `/${siteId}` : '/'}
        component={Link}
        sx={{
          width: '100%',
          '&:-webkit-any-link ': { color: 'inherit' },
          '&:visited': { color: 'inherit' },
        }}
      >
        <FjuelLogo />
      </Box>
      <List sx={{ maxWidth: '100%' }}>
        <MenuTopLevelItem onClick={toggleItem('shorePowerConnect')} icon={<Lightning />}>
          Shore Power Connect
        </MenuTopLevelItem>

        <Collapse in={openMenuItems.shorePowerConnect}>
          <List disablePadding>
            <ListItemNavLink
              indent
              startIcon={<Chalkboard weight="duotone" />}
              disabled={!siteId}
              to={`/${siteId}`}
              onClick={closeAllBut('shorePowerConnect')}
            >
              Dashboard
            </ListItemNavLink>
            <ListItemNavLink
              indent
              startIcon={<Table weight="duotone" />}
              disabled={!siteId}
              to={`/${siteId}/session`}
              onClick={closeAllBut('shorePowerConnect')}
            >
              Sessions
            </ListItemNavLink>
            <ListItemNavLink
              indent
              startIcon={<Boat weight="duotone" />}
              to={`/${siteId}/vessel`}
              onClick={closeAllBut('shorePowerConnect')}
              disabled={!siteId}
            >
              Vessels
            </ListItemNavLink>
            <ListItemNavLink
              indent
              startIcon={<Plug weight="duotone" />}
              to={`/${siteId}/shore-power-units`}
              onClick={closeAllBut('shorePowerConnect')}
              disabled={!siteId}
            >
              Shore Power Units
            </ListItemNavLink>
            <ListItemNavLink
              indent
              startIcon={<Buildings weight="duotone" />}
              to={`/${siteId}/companies`}
              onClick={closeAllBut('shorePowerConnect')}
              disabled={!siteId}
            >
              Companies
            </ListItemNavLink>
          </List>
        </Collapse>
        <MenuTopLevelItem onClick={toggleItem('pricingAndPaymentSolution')} icon={<PriceAndPay />}>
          Pricing and Payment Solution
        </MenuTopLevelItem>
        <Collapse in={openMenuItems.pricingAndPaymentSolution}>
          <List disablePadding>
            <ListItemNavLink
              indent
              startIcon={<Receipt weight="duotone" />}
              to={`/invoice`}
              disabled={!siteId}
              onClick={closeAllBut('pricingAndPaymentSolution')}
            >
              Invoices
            </ListItemNavLink>
            <ListItemNavLink
              indent
              startIcon={<Tag weight="duotone" />}
              to={`/${siteId}/price-markup`}
              onClick={closeAllBut('pricingAndPaymentSolution')}
              disabled={!siteId}
            >
              Price markups
            </ListItemNavLink>
          </List>
        </Collapse>
        <MenuTopLevelItem onClick={toggleItem('portCallPlaner')} icon={<Anchor />}>
          Port Call Planner
        </MenuTopLevelItem>
        <Collapse in={openMenuItems.portCallPlaner}>
          <List disablePadding>
            {me?.isSuperAdmin ? (
              <ListItemNavLink
                indent
                startIcon={<FlagPennant weight="duotone" />}
                to={`/${siteId}/port-call-planer`}
                onClick={closeAllBut('portCallPlaner')}
                disabled={!siteId}
              >
                Overview
              </ListItemNavLink>
            ) : (
              <ListItemNavLink startIcon={<Lock />} to={'/'} disabled>
                Upgrade
              </ListItemNavLink>
            )}
          </List>
        </Collapse>
      </List>
      <Box mt="auto">
        <SiteSelector />
        <ProfileMenu />
        {(me?.isSuperAdmin || me?.isSiteAdmin) && (
          <ButtonNavLink
            sx={{ mt: 2, whiteSpace: 'nowrap' }}
            variant="outlined"
            startIcon={<Users weight="duotone" />}
            to="/users"
          >
            Users administration
          </ButtonNavLink>
        )}
      </Box>
    </Box>
  )
}

const ButtonNavLink: FC<PropsWithChildren<ButtonProps & { to: string }>> = ({ children, ...props }) => {
  const location = useLocation()
  const isMatch = location.pathname === props.to
  const sx = isMatch ? {} : { color: 'inherit', borderColor: 'inherit' }
  return (
    <Button
      component={NavLink}
      variant={isMatch ? 'contained' : 'text'}
      {...props}
      sx={{ ...props.sx, ...sx, px: 2, textTransform: 'none' }}
    >
      {children}
    </Button>
  )
}

const ListItemNavLink: FC<PropsWithChildren<{ to: `/${string}`; indent?: boolean } & ButtonProps>> = ({
  children,
  indent = false,
  ...props
}) => {
  return (
    <ListItem disableGutters disablePadding sx={{ pl: indent ? 4 : undefined, my: 1 }}>
      <ButtonNavLink {...props}>{children}</ButtonNavLink>
    </ListItem>
  )
}

const MenuTopLevelItem: FC<PropsWithChildren<{ onClick: VoidFunction; icon: Required<ButtonProps['startIcon']> }>> = ({
  onClick,
  icon,
  children,
}) => {
  return (
    <ListItem onClick={onClick} disableGutters disablePadding>
      <Button
        size="small"
        variant="text"
        fullWidth
        sx={{
          maxWidth: '100%',
          alignItems: 'baseline',
          color: 'inherit',
          overflowX: 'hidden',
          borderColor: 'inherit',
          textAlign: 'start',
          textTransform: 'none',
          '&:hover': {
            minWidth: 'fit-content',
          },
          '& .MuiTypography-root': {
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
          },
        }}
        startIcon={icon}
      >
        <ListItemText>{children}</ListItemText>
      </Button>
    </ListItem>
  )
}
