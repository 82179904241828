import { Paper, PaperProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

export const Widget: FC<
  PropsWithChildren<{
    sx?: PaperProps['sx']
    component?: PaperProps['component']
    id?: string
  }>
> = ({ children, component, sx, id }) => (
  <Paper component={component ?? 'section'} id={id} sx={{ borderRadius: 2, p: 4, my: 2, overflowX: 'auto', ...sx }}>
    {children}
  </Paper>
)
