import { fjuelApi } from '@fjuel/configs/api'
import { EffectMarkup, EffectMarkupSchema } from '@fjuel/schemas'
import { createMutation } from 'react-query-kit'

export const useUpdateEffectMarkup = createMutation({
  mutationFn: async ({ effectMarkup }: { effectMarkup: EffectMarkup }) => {
    const body = EffectMarkupSchema.omit({ priceMarkupId: true }).parse(effectMarkup)

    return await fjuelApi.patch(`effectMarkup/${body.id}`, { json: body }).json()
  },
})
