import { z } from 'zod'
import { ShorePowerUnitPlugSpecSchema } from './ShorePowerUnitPlugSpecSchema'

export const ShorePowerUnitPlugSchema = z.object({
  effect: z.number().min(0).describe('kVA'),
  amperage: z.number().min(0).describe('A'),
  plug: z.string().nullable(),
  note: z.string().nullable(),
  specifications: z.array(ShorePowerUnitPlugSpecSchema).default([]),
})

export type ShorePowerUnitPlug = z.infer<typeof ShorePowerUnitPlugSchema>
