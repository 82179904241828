import { fjuelApi } from '@fjuel/configs/api'
import { SiteKPI, SiteKPIResponseSchema } from '@fjuel/schemas'
import { createQuery } from 'react-query-kit'
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined'

export const useSiteKpiForPeriod = createQuery<
  SiteKPI,
  { siteId: string | undefined; period: 'monthly' | 'yearly' | 'daily' }
>({
  primaryKey: 'siteKpi',
  queryFn: async ({ queryKey: [, { siteId, period }] }) =>
    SiteKPIResponseSchema.parse(await fjuelApi.get(`site/${siteId}/kpi/${period}`).json()),
  use: [disableIfVariablesUndefined('siteId')],
})

export type useSiteKpiResult =
  | {
      isPending: false
      error: string
      yearly: undefined
      monthly: undefined
      daily: undefined
    }
  | {
      isPending: false
      error: undefined
      yearly: SiteKPI
      monthly: SiteKPI
      daily: SiteKPI
    }
  | {
      isPending: true
      error: undefined
      yearly: undefined
      monthly: undefined
      daily: undefined
    }

export const useSiteKpi = (siteId: string | undefined): useSiteKpiResult => {
  const {
    isError: yearlyError,
    data: yearly,
    isPending: isLoadingYearly,
  } = useSiteKpiForPeriod({
    variables: { siteId, period: 'yearly' },
  })
  const {
    isError: monthlyError,
    data: monthly,
    isPending: isLoadingMonthly,
  } = useSiteKpiForPeriod({
    variables: { siteId, period: 'monthly' },
  })
  const {
    isError: dailyError,
    data: daily,
    isPending: isLoadingDaily,
  } = useSiteKpiForPeriod({ variables: { siteId, period: 'daily' } })

  if (isLoadingYearly || isLoadingMonthly || isLoadingDaily) {
    return {
      isPending: true,
      error: undefined,
      yearly: undefined,
      monthly: undefined,
      daily: undefined,
    }
  }

  if (yearlyError || monthlyError || dailyError) {
    return {
      isPending: false,
      error: 'Error fetching site KPI',
      yearly: undefined,
      monthly: undefined,
      daily: undefined,
    }
  }

  return {
    isPending: false,
    error: undefined,
    yearly,
    monthly,
    daily,
  }
}
