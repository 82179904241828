import { Box, Container, styled } from '@mui/material'
import { SideMenu } from '../SideMenu'

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container maxWidth="xl">
      <Box display="grid" position={'relative'} gridTemplateColumns="minmax(0,262px) minmax(0, 1fr)">
        <SideMenu />
        <Main>{children}</Main>
      </Box>
    </Container>
  )
}
const Main = styled('main')(() => ({
  flexGrow: 1,
  gridColumn: '2 / -1',
}))
