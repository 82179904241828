import { z } from 'zod'
import { LocationSchema } from '../LocationSchema'

export const IPCSchema = z.object({
  id: z.string().uuid(),
  externalId: z.number().int(),
  siteId: z.string().uuid(),
  name: z.string(),
  location: LocationSchema,
})

export type IPC = z.infer<typeof IPCSchema>
