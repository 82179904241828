import { fjuelApi } from '@fjuel/configs/api'
import {
  PriceMarkupUpdateRequest,
  PriceMarkupUpdateRequestSchema,
  transformPriceEnum,
} from '@fjuel/schemas/priceMarkup'
import { createMutation } from 'react-query-kit'

export const useUpdatePriceMarkup = createMutation({
  mutationFn: async ({ priceMarkup }: { priceMarkup: PriceMarkupUpdateRequest }) => {
    const body = PriceMarkupUpdateRequestSchema.transform(transformPriceEnum).parse(priceMarkup)

    return await fjuelApi
      .patch(`priceMarkup/${body.id}`, {
        json: body,
      })
      .json()
  },
})
