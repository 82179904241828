import { z } from 'zod'
import { IMOSchema } from '../IMOSchema'

export const OutgoingInvoiceStatus = { Unknown: 0, Draft: 1, Approved: 2, Sent: 3, Paid: 4, Archived: 5 } as const
export const InvoiceSummarySchema = z.object({
  id: z.string().uuid(),
  invoiceNumber: z.coerce.number().nullable(),
  invoiceDate: z.coerce.date(),
  invoiceDueDate: z.coerce.date(),
  siteId: z.string().uuid(),
  vesselName: z.string().nullable(),
  vesselImo: IMOSchema.nullable(),
  price: z.number(),
  companyName: z.string().nullable(),
  status: z.nativeEnum(OutgoingInvoiceStatus).optional(),
})

export type Invoice = z.infer<typeof InvoiceSummarySchema>

export const InvoicesSchema = z.array(InvoiceSummarySchema)
export type Invoices = z.infer<typeof InvoicesSchema>

export type InvoiceStatus = (typeof OutgoingInvoiceStatus)[keyof typeof OutgoingInvoiceStatus]

export const getInvoiceStatusName = (status: undefined | InvoiceStatus) => {
  switch (status) {
    case OutgoingInvoiceStatus.Draft:
      return 'Draft'
    case OutgoingInvoiceStatus.Approved:
      return 'Approved'
    case OutgoingInvoiceStatus.Sent:
      return 'Sent'
    case OutgoingInvoiceStatus.Paid:
      return 'Paid'
    case OutgoingInvoiceStatus.Archived:
      return 'Archived'
    default:
      return 'Unknown'
  }
}
