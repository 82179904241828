import { z } from 'zod'

export const ShorePowerUnitDetailsSchema = z.object({
  timestamp: z.coerce.date(),
  isActive: z.boolean(),
  totalEnergyConsumed: z.coerce.number(),
  selectedVoltage: z.number().min(0).describe('V'),
  selectedAmperage: z.number().min(0).describe('A'),
  selectedFrequency: z.number().min(0).describe('Hz'),
  activeVoltage: z.number().min(0).describe('V'),
  activeAmperage: z.number().min(0).describe('A'),
  activeEnergyDelivered: z.number().min(0).describe('kWh'),
  activePowerTotal: z.number().min(0).describe('kW'),
})

export type ShorePowerUnitDetail = z.infer<typeof ShorePowerUnitDetailsSchema>

export const ShorePowerUnitDetailsResponseSchema = z.array(ShorePowerUnitDetailsSchema)
// export const ShorePowerUnitDetailsResponseSchema = z.object({
//   usageData: z.array(ShorePowerUnitDetailsSchema),
//   currentLoad: z.number().min(0).max(100).describe('% of max load'),
// })

export type ShorePowerUnitDetailsResponse = z.infer<typeof ShorePowerUnitDetailsResponseSchema>
