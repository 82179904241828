import { fjuelApi } from '@fjuel/configs/api'
import { Vessel, VesselSchema } from '@fjuel/schemas/VesselSchema'
import { createQuery } from 'react-query-kit'
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined'

export const useVessel = createQuery<Vessel, { id: string | undefined }>({
  primaryKey: 'vessel',

  queryFn: async ({ queryKey: [_primaryKey, { id }] }) => VesselSchema.parse(await fjuelApi.get(`vessel/${id}`).json()),
  retry: false,
  use: [disableIfVariablesUndefined(['id'])],
})
