import { coerceStringWithSuffix } from '@fjuel/utils'
import { z } from 'zod'

const pre = (v: unknown) => {
  if (typeof v === 'number') {
    return `${v}V`
  }
  if (typeof v === 'string' && v === '') {
    return coerceStringWithSuffix('V')(0)
  }
  if (v == null) {
    return coerceStringWithSuffix('V')(0)
  }

  return coerceStringWithSuffix('V')(v)
}

export const VoltageSchema = z.preprocess(pre, z.enum(['0V', '400V', '440V', '690V']))
