import { z } from 'zod'
import { VesselSchema } from './VesselSchema'

export const HarbourVesselSchema = VesselSchema.omit({ id: true })
  .extend({
    id: z.string().uuid().nullable(),
    mmsi: z.coerce.number().int().positive().describe('Maritime Mobile Service Identity (MMSI) number'),
    callSign: z.string().min(1).nullable().describe('Call sign of the vessel'),
    length: z.coerce.number().nonnegative().describe('Length of the vessel in meters'),
    width: z.coerce.number().nonnegative().describe('Width of the vessel in meters'),
    trueHeading: z.coerce.number().int().min(0).max(359).describe('True heading of the vessel in degrees'),
    destination: z.string().min(1).nullable().describe('Destination of the vessel'),
    estimatedArrivalTime: z.coerce.date().nullable().describe('Estimated time of arrival of the vessel'),
  })
  .describe('Vessel details for a vessel in harbour')

export type HarbourVessel = z.infer<typeof HarbourVesselSchema>
