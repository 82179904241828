import { z } from 'zod'
import { FrequencySchema } from './FrequencySchema'
import { IMOSchema } from './IMOSchema'
import { LocationSchema } from './LocationSchema'
import { VesselTypeSchema } from './VesselTypeEnum'
import { VoltageSchema } from './VoltageSchema'

export const VesselSchema = z.object({
  id: z.string().uuid(),
  companyId: z.string().uuid().nullable(),
  name: z.string().min(1).nullable(),
  imo: z.preprocess((v) => (v == 0 ? null : v), IMOSchema.nullable()),
  location: LocationSchema.nullable(),
  maxEffectKw: z.coerce.number().nonnegative().describe('Maximum effect of the ship in kW'),
  frequencyHz: FrequencySchema.describe('Frequency of the ship in Hz'),
  voltageV: VoltageSchema.describe('Voltage of the ship in V'),
  status: z.enum(['moored', 'sailing', 'anchored', 'moored and charging', 'unknown']).nullable().default('unknown'),
  shipType: VesselTypeSchema,
})

export type Vessel = z.infer<typeof VesselSchema>
