import { useSiteIpcs } from '@fjuel/hooks'
import { disableIfVariablesUndefined } from '@fjuel/hooks/queries/middlewares/disableIfVariablesUndefined'
import { QueryClient } from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns'
import { createQuery } from 'react-query-kit'
import { IPCWithShorePowerUnit } from '../../schemas/ipc/IPCWithShorePowerUnitSchema'
import { IpcActiveSessions, IpcActiveSessionsSchema } from '../../schemas/ipc/IpcActiveSessionsSchema'
import { addQueryClientToMeta } from './middlewares/addQueryClientToMeta'
import { useIPC } from './useIPC'

export const useActiveSessions = createQuery<
  { sessionData: IpcActiveSessions; totalActiveEnergy: number },
  { siteId: string | undefined }
>({
  primaryKey: 'ipcs-active-sessions',
  queryFn: async ({ queryKey: [, { siteId }], meta }) => {
    const { client } = meta as { client: QueryClient }
    const ipcs = await client.ensureQueryData({
      queryKey: useSiteIpcs.getKey({ siteId }),
      queryFn: useSiteIpcs.queryFn,
    })

    const result = await Promise.allSettled(
      ipcs.map((ipc) => client.fetchQuery({ queryKey: useIPC.getKey({ ipcId: ipc.id }), queryFn: useIPC.queryFn }))
    )

    const sessionData = IpcActiveSessionsSchema.parse(
      result
        .filter((r): r is PromiseFulfilledResult<IPCWithShorePowerUnit> => r.status === 'fulfilled')
        .map((r) => r.value)
    )
    const totalActiveEnergy = sessionData.reduce(
      (acc, ipc) => acc + ipc.shorePowerUnits.reduce((acc, spu) => acc + (spu.session?.activePower ?? 0), 0),
      0
    )

    return { sessionData, totalActiveEnergy }
  },
  refetchInterval: minutesToMilliseconds(2),
  retry: 0,
  use: [addQueryClientToMeta, disableIfVariablesUndefined('siteId')],
})
