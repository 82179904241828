import { useQueryClient } from '@tanstack/react-query'
import { Middleware, QueryHook } from 'react-query-kit'

/**
 * Adds the query client to the meta object.
 * @example ```ts
 * const useActiveSessions = createQuery<TData, TVariable>({
 *  primaryKey: 'KEY',
 * use: [addQueryClientToMeta],
 * queryFn: async ({ meta }) => {
 *   const { client } = meta as { client: QueryClient }
 * // do something with the client
 * },
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addQueryClientToMeta: Middleware<QueryHook<any, any, any>> = (useQueryNext) => {
  return (options) => {
    const client = useQueryClient()

    return useQueryNext({
      ...options,
      meta: { client },
    })
  }
}
