import { Typography, TypographyProps } from '@mui/material'
import { FC, PropsWithChildren, ReactElement } from 'react'

export const TextWithIcon: FC<
  PropsWithChildren<
    { endIcon?: ReactElement; startIcon?: ReactElement; gap?: TypographyProps['gap'] } & TypographyProps
  >
> = ({ endIcon: EndIcon, startIcon: StartIcon, children, gap = 1, ...rest }) => (
  <Typography display={'flex'} alignItems={'center'} gap={gap} {...rest}>
    {StartIcon}
    <span>{children}</span>
    {EndIcon}
  </Typography>
)
