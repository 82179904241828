import { useMsal } from '@azure/msal-react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { House } from '@phosphor-icons/react'
import { FC } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { CenteredContent } from '../CenteredContent'
import FjuelLogo from '../assets/fjuel.svg?react'

export const SignOut: FC = () => {
  const { instance, accounts } = useMsal()
  const navigate = useNavigate()
  return (
    <CenteredContent>
      <Stack alignItems={'center'}>
        <FjuelLogo />
        {!accounts || accounts.length === 0 ? (
          <>
            <Typography variant="h3" component={'h1'} my={2}>
              Not logged in
            </Typography>
            <Button endIcon={<House />} variant="contained" component={RouterLink} to={'/'}>
              Go to home
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h3" component={'h1'} my={2}>
              Leaving so soon?
            </Typography>
            <Box display={'flex'} gap={2}>
              <Button variant="outlined" onClick={() => navigate(-1)}>
                Go back
              </Button>
              <Button variant="contained" onClick={() => instance.logoutRedirect()}>
                Sign out
              </Button>
            </Box>
          </>
        )}
      </Stack>
    </CenteredContent>
  )
}
