import { z } from 'zod'
import { booleanPreprocess, defaultDotnetValueToNull } from '../preprocessors'

export const MeSchema = z.object({
  isSuperAdmin: z.preprocess(booleanPreprocess, z.boolean()),
  isSiteAdmin: z.preprocess(booleanPreprocess, z.boolean()),
  sites: z.array(z.string().uuid()),
  vessels: z.array(z.string().uuid()),
  companyId: z.preprocess(defaultDotnetValueToNull, z.string().uuid().nullable().default(null)),
})

export type Me = z.infer<typeof MeSchema>
