import { z } from 'zod'

export const EffectMarkupSchema = z.object({
  fixedCostKr: z.coerce.number().int().positive().describe('Markup per kWh in NOK/øre'),
  id: z.string().uuid().describe('Unique identifier of the effect markup').optional(),
  priceMarkupId: z.string().uuid().describe('Unique identifier of the price markup').nullable().default(null),
  upperLimitInKw: z.coerce
    .number()
    .int('Upper limit must be an integer')
    .positive('Upper limit must be a positive integer')
    .describe('Upper limit of the effect in kW'),
  delete: z.boolean().optional(),
})

export type EffectMarkup = z.infer<typeof EffectMarkupSchema>

export const EffectMarkupsSchema = z.array(EffectMarkupSchema).superRefine((data, ctx) => {
  const upperLimits = data.map((em) => em.upperLimitInKw)

  let duplicateUpperLimit: number
  const hasDuplicateUpperLimits = upperLimits.some((ul, index) => {
    const foundDuplicate = upperLimits.indexOf(ul) !== index
    if (foundDuplicate) {
      duplicateUpperLimit = ul
    }
    return foundDuplicate
  })

  if (hasDuplicateUpperLimits) {
    const indexOfDuplicateUpperLimits = upperLimits.reduce((acc, ul, currentIndex) => {
      return ul === duplicateUpperLimit ? [...acc, currentIndex] : acc
    }, [] as number[])
    indexOfDuplicateUpperLimits.forEach((ul) => {
      ctx.addIssue({
        message: 'Upper limits must be unique',
        code: 'custom',
        path: [ul, 'upperLimitInKw'],
      })
    })
    ctx.addIssue({ message: 'Upper limits must be unique', code: 'custom' })
  }
  return z.NEVER
})
