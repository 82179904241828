import { fjuelApi } from '@fjuel/configs/api'
import {
  CreatePriceMarkup,
  CreatePriceMarkupSchema,
  PriceMarkupSchema,
  transformPriceEnum,
} from '@fjuel/schemas/priceMarkup'
import { createMutation } from 'react-query-kit'

export const useCreatePriceMarkup = createMutation({
  mutationFn: async ({ priceMarkup }: { priceMarkup: CreatePriceMarkup }) =>
    PriceMarkupSchema.parse(
      await fjuelApi
        .post(`priceMarkup`, {
          json: CreatePriceMarkupSchema.transform(transformPriceEnum).parse(priceMarkup),
        })
        .json()
    ),
})
