import { Container, Grid } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

export const CenteredContent: FC<PropsWithChildren> = ({ children }) => (
  <Container>
    <Grid container minHeight={'100dvh'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
      {children}
    </Grid>
  </Container>
)
