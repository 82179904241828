import { z } from 'zod'

export const SiteKPIResponseSchema = z.object({
  earnings: z.number(),
  consumption: z.number().int(),
  emissionSaved: z.number(),
  sessionCount: z.number().int().nonnegative(),
  currentConsumption: z.number().nonnegative(),
})

export type SiteKPI = z.infer<typeof SiteKPIResponseSchema>
