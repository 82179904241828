import { coerceStringWithSuffix } from '@fjuel/utils'
import { z } from 'zod'

const pre = (v: unknown) => {
  if (typeof v === 'number') {
    return `${v}hz`
  }
  if (typeof v === 'string' && v === '') {
    return coerceStringWithSuffix('hz')(0)
  }
  if (v == null) {
    return coerceStringWithSuffix('hz')(0)
  }
  return coerceStringWithSuffix('hz')(v)
}

export const FrequencySchema = z.preprocess(pre, z.enum(['0hz', '50hz', '60hz']))
