import { SvgIcon } from '@mui/material'
import { forwardRef } from 'react'

export const Ship = forwardRef<SVGSVGElement>(function Ship(props, ref) {
  return (
    <SvgIcon {...props} ref={ref}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M0.979248 22.9538C3.32716 23.4521 5.29175 21.0371 5.29175 21.0371C5.6495 21.6062 6.14164 22.0786 6.72485 22.4128C7.30806 22.7471 7.96447 22.9328 8.63633 22.9538C9.31459 22.951 9.98063 22.773 10.5699 22.4373C11.1593 22.1015 11.6519 21.6192 12.0001 21.0371C12.3445 21.6183 12.8336 22.1004 13.4198 22.4363C14.0059 22.7722 14.6691 22.9505 15.3447 22.9538C16.0191 22.9326 16.6781 22.7469 17.2644 22.4129C17.8506 22.0788 18.3464 21.6065 18.7084 21.0371C18.7084 21.0371 20.673 23.4521 23.0209 22.9538" />
        <path d="M12.4697 18.2294V9.49902" />
        <path d="M22.5322 11.4157L12.4697 9.49902L2.40723 11.4157" />
        <path d="M21.5643 11.2334L19.4368 19.7338" />
        <path d="M3.375 11.2334L5.30125 19.0246" />
        <path d="M18.2197 10.6012V7.72624C18.2197 7.21791 18.0178 6.73039 17.6583 6.37095C17.2989 6.0115 16.8114 5.80957 16.3031 5.80957H8.63639C8.12806 5.80957 7.64055 6.0115 7.28111 6.37095C6.92166 6.73039 6.71973 7.21791 6.71973 7.72624V10.6012" />
        <path d="M14.8654 7.6875H10.0737" />
        <path d="M14.3862 5.77116V1.45866C14.3863 1.33322 14.3371 1.21277 14.2493 1.12318C14.1615 1.03359 14.0421 0.982001 13.9167 0.979492H11.0417C10.9146 0.979492 10.7927 1.02998 10.7028 1.11984C10.613 1.2097 10.5625 1.33158 10.5625 1.45866V5.77116" />
        <path d="M14.3863 3.85449H10.553" />
        <path d="M20.7976 14.3674L12.4697 12.374L4.15137 14.3482" />
      </svg>
    </SvgIcon>
  )
})
