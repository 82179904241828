import { fjuelApi } from '@fjuel/configs/api'
import { InvoiceDetails, InvoiceDetailsSchema } from '@fjuel/schemas/invoice/InvoiceDetailsSchema'
import { createQuery } from 'react-query-kit'
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined'

export const useInvoiceDetails = createQuery<InvoiceDetails, { invoiceId: string | undefined }>({
  primaryKey: 'invoiceDetails',
  queryFn: async ({ queryKey: [, { invoiceId }] }) =>
    InvoiceDetailsSchema.parse(await fjuelApi.get(`invoice/site/${invoiceId}/details`).json()),
  use: [disableIfVariablesUndefined('invoiceId')],
})
