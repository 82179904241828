import { Widget } from '@fjuel/components'
import { WidgetHeader } from '@fjuel/components/WidgetHeader'
import { Typography } from '@mui/material'
import { Outlet, createBrowserRouter } from 'react-router-dom'
import { ApplicationContextProvider } from '../ApplicationContext'
import { RouterErrorBoundary } from '../RouterErrorBoundary'
import { queryClient } from '../configs/queryClient'
import { dashboardSiteDataLoader, sessionDataLoader, sitesDataLoader } from '../hooks'
import { MsalAppStateManager } from '../msal/MsalAppStateManager'
import { Layout } from './Layout'
import { ProtectedRoute } from './ProtectedRoute'
import { SignIn } from './SignIn'
import { SignOut } from './SignOut'

export const router = createBrowserRouter([
  {
    path: 'auth',
    children: [
      { path: 'sign-in', element: <SignIn /> },
      { path: 'sign-out', element: <SignOut /> },
    ],
  },
  {
    path: '/',
    errorElement: <RouterErrorBoundary />,
    element: (
      <MsalAppStateManager>
        <ApplicationContextProvider>
          <Layout>
            <Outlet />
          </Layout>
        </ApplicationContextProvider>
      </MsalAppStateManager>
    ),
    children: [
      {
        index: true,
        lazy: async () => {
          const { SitePicker } = await import('../SitePicker')
          return {
            Component: SitePicker,
          }
        },
        loader: sitesDataLoader(queryClient),
      },
      {
        path: ':siteId',
        element: <Outlet />,
        children: [
          {
            index: true,
            lazy: async () => {
              const { DashboardPage } = await import('./dashboard/Dashboard.page')
              const { DashboardError } = await import('./dashboard/DashboardError')
              return {
                Component: DashboardPage,
                errorElement: <DashboardError />,
              }
            },
            loader: dashboardSiteDataLoader(queryClient),
          },
          {
            path: 'shore-power-units',
            lazy: async () => {
              const { ShorePowerUnitsOverviewPage } = await import('./shorePowerUnits/ShorePowerUnitsOverview.page')
              return { Component: ShorePowerUnitsOverviewPage }
            },
          },
          {
            path: 'vessel',
            element: <Outlet />,
            children: [
              {
                index: true,
                lazy: async () => {
                  const { VesselDashboardPage } = await import('./vessel/VesselDashboard.page')
                  return { Component: VesselDashboardPage }
                },
              },
              {
                path: ':id',
                lazy: async () => {
                  const { VesselPage } = await import('./vessel/Vessel.page')
                  return { Component: VesselPage }
                },
              },
            ],
          },
          {
            path: 'session',
            children: [
              {
                index: true,
                lazy: async () => {
                  const { SessionIndex } = await import('./session/SessionIndex.page')
                  return { Component: SessionIndex }
                },
              },
              {
                path: ':sessionId',
                lazy: async () => {
                  const { SessionDetailsPage } = await import('./session/SessionDetails.page')
                  return { Component: SessionDetailsPage }
                },
                loader: sessionDataLoader(queryClient),
              },
            ],
          },
          {
            path: 'price-markup',
            lazy: async () => {
              const { SiteMarkupPage } = await import('./site/SiteMarkup.page')
              return { Component: SiteMarkupPage }
            },
          },
          {
            path: 'companies',
            children: [
              {
                index: true,
                lazy: async () => {
                  const { CompanyPage } = await import('./company/Company.page')
                  return { Component: CompanyPage }
                },
              },
              {
                path: ':companyId',
                lazy: async () => {
                  const { CompanyDetailsPage } = await import('./company/CompanyDetails.page')
                  return { Component: CompanyDetailsPage }
                },
              },
            ],
          },
          {
            path: 'port-call-planer',
            element: (
              <ProtectedRoute
                fallbackMessage={
                  <Widget>
                    <WidgetHeader>Not included in subscription</WidgetHeader>
                    <Typography>Upgrade subscription to access Port Call Planer</Typography>
                  </Widget>
                }
              >
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                lazy: async () => {
                  const { PortCallPlanerOverview } = await import('./portCallPlaner/PortCallPlanerOverview.page')
                  return { Component: PortCallPlanerOverview }
                },
              },
            ],
          },
        ],
      },
      {
        path: 'invoice',
        children: [
          {
            index: true,
            lazy: async () => {
              const { InvoiceOverviewPage } = await import('./invoice/InvoiceOverview.page')
              return { Component: InvoiceOverviewPage }
            },
          },
          {
            path: ':invoiceId',
            lazy: async () => {
              const { InvoiceDetailsPage } = await import('./invoice/InvoiceDetails.page')
              return { Component: InvoiceDetailsPage }
            },
          },
        ],
      },
      {
        path: 'users',
        element: (
          <ProtectedRoute allowedRoles={{ siteAdmin: true }}>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            lazy: async () => {
              const { UsersPage } = await import('./users/Users.page')
              return { Component: UsersPage }
            },
          },
        ],
      },
    ],
  },
  {
    path: 'type-documentation',
    lazy: async () => {
      const { TypeDocumentationPage } = await import('../TypeDocumentation.page')
      return { Component: TypeDocumentationPage }
    },
  },
])
