import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { Box, Button, Typography } from '@mui/material'
import { Navigate } from 'react-router-dom'
import { CenteredContent } from '../CenteredContent'
import FjuelLogo from '../assets/fjuel.svg?react'

export const SignIn = () => {
  const { inProgress, instance } = useMsal()
  const isAuth = useIsAuthenticated()
  if (isAuth) {
    return <Navigate to="/" />
  }

  return (
    <CenteredContent>
      <Box>
        <FjuelLogo />
        <Typography component={'h1'} variant="h3">
          Sign in to Fjuel
        </Typography>
        <Button disabled={inProgress !== 'none'} onClick={() => instance.loginRedirect()}>
          Login
        </Button>
      </Box>
    </CenteredContent>
  )
}
