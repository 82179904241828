import { z } from 'zod'
import { CableHandlingCostSchema } from './CableHandlingCostSchema'
import { EffectMarkupsSchema } from './EffectMarkupsSchema'
import { DynamicPriceMarkup, StaticPriceMarkup } from './PriceMarkupSchema'

export const UpsertPriceMarkupBase = z.object({
  cableHandlingKr: CableHandlingCostSchema,
  effectMarkups: EffectMarkupsSchema,
  ipcId: z.string(),
  siteCompanyId: z.string().uuid().optional(),
  id: z.string().uuid().nullish(),
})
export type UpsertPriceMarkupBase = z.infer<typeof UpsertPriceMarkupBase>
export const UpsertPriceMarkupSchema = z.discriminatedUnion('priceType', [
  StaticPriceMarkup.merge(UpsertPriceMarkupBase),
  DynamicPriceMarkup.merge(UpsertPriceMarkupBase),
])
export type UpsertPriceMarkup = z.infer<typeof UpsertPriceMarkupSchema>
