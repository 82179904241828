import { z } from 'zod'
import { VesselSchema } from './VesselSchema'

export const VesselFormSchema = VesselSchema.pick({
  name: true,
  imo: true,
  frequencyHz: true,
  maxEffectKw: true,
  voltageV: true,
}).extend({ id: z.string().uuid().nullable() })

export type VesselFormData = z.infer<typeof VesselFormSchema>
