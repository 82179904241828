import { fjuelApi } from '@fjuel/configs/api'
import { Vessel } from '@fjuel/schemas'
import { createMutation } from 'react-query-kit'

export const useSetSessionVessel = createMutation({
  mutationFn: async ({ vessel, sessionId }: { vessel: Vessel; sessionId: string }) => {
    return await fjuelApi
      .patch(`session/${sessionId}`, {
        json: { vesselId: vessel.id },
      })
      .json()
  },
})
