import { fjuelApi } from '@fjuel/configs/api'
import { UpdateVesselFormData, UpdateVesselSchema, VesselSchema } from '@fjuel/schemas'
import { createMutation } from 'react-query-kit'

export const useUpdateVessel = createMutation({
  mutationFn: async ({ id, vessel }: { vessel: UpdateVesselFormData; id: string }) => {
    return VesselSchema.parse(
      await fjuelApi
        .patch(`vessel/${id}`, {
          json: UpdateVesselSchema.parse(vessel),
        })
        .json()
    )
  },
})
