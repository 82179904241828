import { Tooltip } from '@mui/material'
import { ArrowDown, ArrowUp, ArrowsDownUp } from '@phosphor-icons/react'

export const tableSortingIcons = {
  desc: (
    <Tooltip arrow title={'Descending'}>
      <ArrowUp style={{ fontSize: '1.1em', marginLeft: '0.5rem' }} />
    </Tooltip>
  ),
  asc: (
    <Tooltip arrow title={'Ascending'}>
      <ArrowDown style={{ fontSize: '1.1em', marginLeft: '0.5rem' }} />
    </Tooltip>
  ),
  false: (
    <Tooltip arrow title={'No sorting'}>
      <ArrowsDownUp style={{ fontSize: '1.1em', marginLeft: '0.5rem', opacity: 0.5 }} />
    </Tooltip>
  ),
} as const
