import { fjuelApi } from '@fjuel/configs/api'
import { IPC, IPCSchema } from '@fjuel/schemas'
import { createQuery } from 'react-query-kit'
import { z } from 'zod'
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined'

export const useSiteIpcs = createQuery<IPC[], { siteId: string | undefined }>({
  primaryKey: 'site-ipcs',
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    z.array(IPCSchema).parse(await fjuelApi.get(`site/${siteId}/ipcs`).json()),
  use: [disableIfVariablesUndefined(['siteId'])],
})
