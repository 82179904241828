import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const GlowingCircle: FC<{ color: 'lightGreen' | 'pink' | 'grey'; sx?: SvgIconProps['sx'] }> = ({
  color,
  ...rest
}) => {
  return (
    <SvgIcon {...rest}>
      {color === 'pink' ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <g filter="url(#filter0_d_333_19244)">
            <circle cx="9" cy="9" r="5" fill="#E54C91" />
          </g>
          <defs>
            <filter
              id="filter0_d_333_19244"
              x="0"
              y="0"
              width="18"
              height="18"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.898039 0 0 0 0 0.298039 0 0 0 0 0.568627 0 0 0 1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_333_19244" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_333_19244" result="shape" />
            </filter>
          </defs>
        </svg>
      ) : color === 'grey' ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <g filter="url(#filter0_d_405_29250)">
            <circle cx="9" cy="9" r="5" fill="#C8C8C8" />
          </g>
          <defs>
            <filter
              id="filter0_d_405_29250"
              x="0"
              y="0"
              width="18"
              height="18"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.784314 0 0 0 0 0.784314 0 0 0 0 0.784314 0 0 0 1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_405_29250" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_405_29250" result="shape" />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <g filter="url(#filter0_d_333_19247)">
            <circle cx="9" cy="9" r="5" fill="#8BFCB6" />
          </g>
          <defs>
            <filter
              id="filter0_d_333_19247"
              x="0"
              y="0"
              width="18"
              height="18"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.545098 0 0 0 0 0.988235 0 0 0 0 0.713726 0 0 0 1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_333_19247" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_333_19247" result="shape" />
            </filter>
          </defs>
        </svg>
      )}
    </SvgIcon>
  )
}
