import { Box, BoxProps, Typography } from '@mui/material'
import { FC } from 'react'
import { CopyToClipboard } from './CopyToClipboard'

export const InlineStat: FC<{ label: string; value: string; sx?: BoxProps['sx']; copyable?: boolean }> = ({
  label,
  value,
  sx,
  copyable = false,
}) => {
  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} sx={sx}>
      <Typography fontWeight={300} fontSize={12}>
        {label}:
      </Typography>
      <Typography fontWeight={600} fontSize={14}>
        {value} {copyable && <CopyToClipboard text={value} />}
      </Typography>
    </Box>
  )
}
