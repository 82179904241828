import { createEnv } from '@t3-oss/env-core'
import z from 'zod'

export const env = createEnv({
  /*
   * Specify what prefix the client-side variables must have.
   * This is enforced both on type-level and at runtime.
   */
  clientPrefix: 'VITE_',
  client: {
    VITE_MSAL_CLIENT_ID: z.string().uuid(),
    VITE_API_URL: z.string().url(),
    VITE_MAPBOX_TOKEN: z.string().nonempty(),
  },
  /**
   * What object holds the environment variables at runtime.
   * Often `process.env` or `import.meta.env`
   */
  runtimeEnv: import.meta.env,
})
