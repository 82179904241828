import { z } from 'zod'
import { IMOSchema } from './IMOSchema'
import { ShorePowerUnitSchema } from './ShorePowerUnitSchema'
import { VesselSchema } from './VesselSchema'
import { FrequencySchema, VoltageSchema } from '.'

export const ActiveSessionSchema = z.object({
  id: z.string().uuid(),
  shorePowerUnitId: z.string().uuid(),
  shorePowerUnit: ShorePowerUnitSchema.nullable(),
  vesselImo: IMOSchema.nullable(),
  vesselImoChecked: z.boolean(),
  vesselId: z.string().uuid().nullable(),
  vessel: VesselSchema,
  timeStarted: z.coerce.date(),
  activePower: z.number().nonnegative().nullish(),
  voltage: VoltageSchema,
  frequency: FrequencySchema,
  amperage: z.string(),
  powerPeak: z.number().nonnegative(),
  totalEnergyConsumed: z.number(),
  averagePower: z.number().nonnegative(),
})

export type ActiveSession = z.infer<typeof ActiveSessionSchema>
