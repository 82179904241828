import { Typography, TypographyProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

export const WidgetHeader: FC<PropsWithChildren<TypographyProps>> = ({ children, ...rest }) => {
  return (
    <Typography
      variant="h4"
      component={rest.component ?? 'h1'}
      color={'primary'}
      sx={{ 'text-wrap': 'balance' }}
      gutterBottom
      {...rest}
    >
      {children}
    </Typography>
  )
}
