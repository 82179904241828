import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { CircularProgress, styled } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Check, Info, Warning, XCircle } from '@phosphor-icons/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { nb } from 'date-fns/locale'
import { MaterialDesignContent, SnackbarProvider } from 'notistack'
import { FC, Suspense, lazy, useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import { router } from './pages/router'

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/production').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
)

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#8BFCB6',
    color: '#2a4c37',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#8c314b',
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: 'hsla(44, 100%, 50%, 1)',
    color: 'hsla(44, 100%, 20%, 1)',
  },
}))

export const App: FC<{ pca: IPublicClientApplication }> = ({ pca }) => {
  const [showDevtools, setShowDevtools] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      iconVariant={{
        success: <Check size={24} style={{ marginInlineEnd: '.5rem' }} />,
        error: <XCircle size={24} style={{ marginInlineEnd: '.5rem' }} />,
        warning: <Warning size={24} style={{ marginInlineEnd: '.5rem' }} />,
        info: <Info size={24} style={{ marginInlineEnd: '.5rem' }} />,
      }}
    >
      <MsalProvider instance={pca}>
        <ReactQueryDevtools />
        {showDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </Suspense>
        )}
        <Suspense fallback={<CircularProgress color="primary" />}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </Suspense>
      </MsalProvider>
    </SnackbarProvider>
  )
}
